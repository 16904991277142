import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FaTimes } from 'react-icons/fa'
import styled from '@emotion/styled'

import { PageIntro, NewsletterForm, FormSection } from '#sections'
import { Layout, SectionTitle, LotBlock, ArrowButton } from '#components'
import { useDebounce } from '#hooks'
import maxPrices from './maxPrices'

const StyledSelectWrap = styled.div`
  position: relative;

  span {
    position: absolute;
    top: 50%;
    right: 17px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 6px 0 6px;
    border-color: #000 transparent transparent transparent;
    pointer-events: none;
    transition: all 0.3s;
  }

  select:active {
    ~ span {
      transform: translateY(-50%) rotate(180deg);
    }
  }
`

export default function Auction({ pageContext: { lots, featuredImage } }) {
  const [perPage, setPerPage] = useState(400)
  const [query, setQuery] = useState(``)
  const [maxPrice, setMaxPrice] = useState(`999999999999999999`)
  const [filteredLots, setFilteredLots] = useState(lots)
  const [currLots, setCurrLots] = useState(filteredLots.slice(0, perPage))
  const debouncedQuery = useDebounce(query, 500)

  useEffect(() => {
    setCurrLots(filteredLots.slice(0, perPage))
  }, [perPage, filteredLots])

  useEffect(() => {
    const newLots = lots.filter((lot) => {
      const toCheck = `lot ${lot.LotNumber} ${lot.FullAddress}`.toLowerCase()

      return (
        toCheck.includes(debouncedQuery.toLowerCase()) &&
        (!lot.GuidePrice ||
          parseInt(lot.GuidePrice.replace(/[^0-9]/g, ``)) <= maxPrice)
      )
    })

    setFilteredLots(newLots)
  }, [debouncedQuery, lots, maxPrice])

  return (
    <Layout title="Private Treaty Properties">
      <PageIntro
        title="Private Treaty Properties"
        preview={false}
        backgroundImage={featuredImage}
      />
      <div className="bg-white relative pb-55 md:pb-65">
        <div className="container pt-45 md:pt-55">
          <div className="flex flex-wrap -mx-8">
            <div className="w-full lg:w-2/3 px-8">
              <div className="flex items-center flex-wrap -mx-8">
                <div className="w-full px-8 pb-20">
                  <SectionTitle title="Private Treaty Department" />
                </div>
                <div className="w-full px-8 md:w-auto md:min-w-330">
                  <div className="relative">
                    <button
                      type="button"
                      className={`flex justify-between items-center absolute top-100p inset-x-0 bg-gray-500 text-white font-semibold text-13 py-5 text-left px-16 border-t-0 w-full transition-all duration-300 border-b-2 border-l-2 border-r-2 border-gray-200 outline-none hover:bg-gray-700 transform ${
                        query === ``
                          ? `opacity-0 invisible -translate-y-full`
                          : `opacity-100 visible translate-y-0`
                      }`}
                      onClick={() => setQuery(``)}
                    >
                      Reset Search Query
                      <FaTimes className="mb-1" />
                    </button>
                    <input
                      className={`relative block w-full border-2 bg-white text-17 md:text-19 placeholder-gray-500 text-black pt-7 pb-5 px-16 leading-normal outline-none transition-all duration-300 hover:shadow-md hover:border-gray-400 focus:border-gray-500 ${
                        query === ``
                          ? `border-gray-200`
                          : `border-gray-500 shadow-md`
                      }`}
                      type="text"
                      autoComplete="off"
                      name="search"
                      id="search"
                      placeholder="Lot No, Postcode, Address"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-1/2 px-8 md:w-210">
                  <StyledSelectWrap>
                    <button
                      type="button"
                      className={`flex justify-between items-center absolute top-100p inset-x-0 bg-gray-500 text-white font-semibold text-13 py-5 text-left px-16 border-t-0 w-full transition-all duration-300 border-b-2 border-l-2 border-r-2 border-gray-200 outline-none hover:bg-gray-700 transform ${
                        maxPrice === `999999999999999999`
                          ? `opacity-0 invisible -translate-y-full`
                          : `opacity-100 visible translate-y-0`
                      }`}
                      onClick={() => setMaxPrice(`999999999999999999`)}
                    >
                      Reset Max Price
                      <FaTimes className="mb-1" />
                    </button>
                    <select
                      name="max-price"
                      id="max-price"
                      className={`relative outline-none block w-full bg-gray-200 text-17 md:text-19 px-16 leading-normal pt-7 pb-5 appearance-none transition-all duration-300 hover:shadow-md hover:border-gray-400 focus:border-gray-500 border-2 cursor-pointer ${
                        maxPrice === `999999999999999999`
                          ? `border-gray-200`
                          : `border-gray-500 shadow-md`
                      }`}
                      value={maxPrice}
                      onChange={(e) => setMaxPrice(e.target.value)}
                    >
                      <option value="999999999999999999" disabled>
                        Max Price
                      </option>
                      {maxPrices.map(({ value, label }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                    <span />
                  </StyledSelectWrap>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/3 px-8" />
          </div>

          <div className="pt-45 md:pt-65 pb-25 lg:pb-55">
            <div className="flex flex-wrap -mx-8">
              {currLots.map((lot) => (
                <div
                  className="w-full md:w-1/2 lg:w-1/3 px-8 mb-40"
                  key={lot.ID}
                >
                  <LotBlock truncated lot={lot} />
                </div>
              ))}
              {!currLots.length && (
                <div className="text-center py-65 text-27 font-semibold w-full">
                  Sorry, no results found for your search query.
                </div>
              )}
            </div>
            {perPage < filteredLots.length && (
              <div className="text-center pt-40">
                <ArrowButton
                  variant="accent"
                  type="button"
                  onClick={() => setPerPage(perPage + 6)}
                >
                  Load More
                </ArrowButton>
              </div>
            )}
          </div>
        </div>
      </div>
      <NewsletterForm />
      <FormSection />
    </Layout>
  )
}

Auction.propTypes = {
  pageContext: PropTypes.object.isRequired,
}
