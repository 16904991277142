export default [
  { value: `100000`, label: `£100,000` },
  { value: `150000`, label: `£150,000` },
  { value: `200000`, label: `£200,000` },
  { value: `250000`, label: `£250,000` },
  { value: `300000`, label: `£300,000` },
  { value: `350000`, label: `£350,000` },
  { value: `400000`, label: `£400,000` },
  { value: `450000`, label: `£450,000` },
  { value: `500000`, label: `£500,000` },
  { value: `550000`, label: `£550,000` },
  { value: `600000`, label: `£600,000` },
  { value: `650000`, label: `£650,000` },
  { value: `700000`, label: `£700,000` },
  { value: `750000`, label: `£750,000` },
  { value: `800000`, label: `£800,000` },
  { value: `850000`, label: `£850,000` },
  { value: `900000`, label: `£900,000` },
  { value: `1000000`, label: `£1,000,000` },
  { value: `1100000`, label: `£1,100,000` },
  { value: `1200000`, label: `£1,200,000` },
  { value: `1300000`, label: `£1,300,000` },
  { value: `1500000`, label: `£1,500,000` },
  { value: `1600000`, label: `£1,600,000` },
  { value: `1700000`, label: `£1,700,000` },
  { value: `1800000`, label: `£1,800,000` },
  { value: `1900000`, label: `£1,900,000` },
  { value: `2000000`, label: `£2,000,000` },
  { value: `1000000000`, label: `£2,000,000+` },
]
